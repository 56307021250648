import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const SimplePage = ({ title, prefix, eyebrow, subline, image, alt, children, ...other }) => {
    return (
        <article className="container section" {...other}>
            <header className="col mb-md lg:mb-lg">
                {prefix && (prefix)}
                {eyebrow &&
                    <h3 className="section-heading">{eyebrow}</h3>
                }
                <h1 className="my-0 lg:w-3/4">{title}</h1>
                {subline &&
                    <div className="text-gray mt-2 md:text-lg">{subline}</div>
                }
            </header>
            <div className="clearfix pt-sm lg:flex">
                {image &&
                    <div className="col lg:w-2/5">
                        <div className="offset-image-left mb-md">
                            <GatsbyImage image={image} alt={alt || title} />
                        </div>
                    </div>
                }
                <div className={`col md:w-3/4 md:mr-0 md:ml-auto ${image ? "lg:w-3/5" : "lg:w-3/4"}`}>
                    {children}
                </div>
            </div>
        </article>
    )
}

export default SimplePage
